import React from "react";

import btnToggleGrid from "src/images/btn-gallery-grid.png";

/**
 * Specific Gallery Component
 */
class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.createSpecificGallery = this.createSpecificGallery.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.modalNavigation = this.modalNavigation.bind(this);
    this.modalGridLayout = this.modalGridLayout.bind(this);
    this.gridState = this.gridState.bind(this);
    this.showCaption = this.showCaption.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.monitorModal = this.monitorModal.bind(this);
    this.createGalleryProperties = this.createGalleryProperties.bind(this);
  }

  state = {
    modalOpen: false,
    modalIndex: null,
    modalGridView: false,
    specificGalleryInfo: [],
    galleryImages: []
  }

  componentDidMount() {
    const galleryData = JSON.parse(this.props.galleryConfig);
    const slug = galleryData.slug;

    if (typeof window !== undefined) {
      window.addEventListener("keydown", this.handleKeyDown);
      window.addEventListener("resize", this.monitorModal);
    }

    fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/envira-gallery?slug=${slug}`)
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ specificGalleryInfo: this.createGalleryProperties(json) });
        } else {
          console.log("Data did not load");
        }
      })
  }

  componentWillUnmount() {
    if (typeof window !== undefined) {
      window.removeEventListener("keydown", this.handleKeyDown);
      window.removeEventListener("resize", this.monitorModal);
    }
  }

  /**
   * FORMAT GALLERY AND PROVIDE NECESSARY PROPERTIES
  */
  createGalleryProperties(data) {
    let galleryArray = data;
    let imageDownloads = this.props.downloadable;

    for (let outerIndex = 0; outerIndex < galleryArray.length; outerIndex++) {
      let galleryData = galleryArray[outerIndex] ? galleryArray[outerIndex].gallery_data.gallery : [];

      for (let index = 0; index < galleryData.length; index++) {
        // check if image is video or not
        if (galleryData[index].link.indexOf('vimeo.com') > -1 || galleryData[index].link.indexOf('youtube.com') > -1 || galleryData[index].link.indexOf('youtu.be') > -1) {
          galleryData[index]["isVideo"] = true;

          if (galleryData[index].link.indexOf('vimeo.com') > -1) {
            galleryData[index].link = galleryData[index].link.replace("vimeo.com/", "player.vimeo.com/video/");
          } else if (galleryData[index].link.indexOf('youtube.com') > -1) {
            galleryData[index].link = galleryData[index].link.replace("watch?v=", "embed/");
          } else if (galleryData[index].link.indexOf('youtu.be') > -1) {
            galleryData[index].link = galleryData[index].link.replace("https://youtu.be/", " https://www.youtube.com/embed/");
          }
        } else {
          galleryData[index]["isVideo"] = false;
        }

        // temporary check to see if image is donwloadable (need to replace by adding download status to API response)
        if (imageDownloads.children.length > 0) {
          for (let downloadIndex = 0; downloadIndex < imageDownloads.children.length; downloadIndex++) {
            if (galleryData[index].id.toString() === imageDownloads.children[downloadIndex].attribs.id.split("-")[3]) {
              if (imageDownloads.children[downloadIndex].children[0].children[3].children) {
                if (imageDownloads.children[downloadIndex].children[0].children[3].children[0] !== undefined) {
                  galleryData[index]["downloadLink"] = imageDownloads.children[downloadIndex].children[0].children[3].children[0].children[1].attribs.href;
                } else {
                  galleryData[index]["downloadLink"] = "";
                }
              }
            }
          }
        }
      }
    }

    return data;
  }

  createSpecificGallery(gallery) {
    let galleryArray = [];

    for (let index = 0; index < gallery.length; index++) {
      let tagArray = (gallery[index].tags && gallery[index].tags.length > 0) ? gallery[index].tags : [];
      let tallImage = gallery[index].height > gallery[index].width ? true : false;
      let image;

      if (typeof window !== undefined) {
        if (gallery[index].isVideo && window.innerWidth < 912) {
          image = (
            <div key={index} className="single-gallery__image" onClick={() => this.toggleModal(index)}>
              <div className="video-wrapper">
                <iframe src={gallery[index].link} allowFullScreen loading="lazy"></iframe>
              </div>

              <div className="gallery-text-container-mobile"></div>
            </div>
          )
        } else {
          image = (
            <div key={index} className="single-gallery__image" onClick={() => this.toggleModal(index)}>
              <div className="gallery-image-container">
                <img src={gallery[index].src} className={`gallery-cover-image ${tallImage ? 'tall-image' : ''}`} />
                {
                  gallery[index].isVideo &&
                  <svg className="icon icon--play"><use xlinkHref="#icon-play" /></svg>
                }
              </div>
              <h4 className="gallery-title">{gallery[index].title.replace(/&#8211;/g, "-").replace(/&#038;/g, "&").replace(/&#8216;|&#8217;/g, "'").replace(/&amp;/g, "&")}</h4>
              <div className="gallery-text-container">
                {
                  tagArray.map((tag, index) => <p key={index} className="gallery-text tag-text">{tag}</p>)
                }
              </div>

              {
                gallery[index].downloadLink !== "" &&
                <div className="download-container-mobile">
                  <a href={gallery[index].downloadLink} className="image-download-mobile">
                    <svg className="icon icon--download"><use xlinkHref="#icon-download" /></svg>
                  </a>
                </div>
              }

              <button type="button" className="gallery-expand" data-index={index} onClick={this.showCaption}>
                <svg className="icon icon--ellipsis"><use xlinkHref="#icon-ellipsis" /></svg>
                <svg className="icon icon--close"><use xlinkHref="#icon-close" /></svg>
              </button>

              <div className="gallery-text-container-mobile">
                <div className="text-wrapper">
                  <h4 className="mobile-gallery-title">{gallery[index].title.replace(/&#8211;/g, "-").replace(/&#038;/g, "&").replace(/&#8216;|&#8217;/g, "'").replace(/&amp;/g, "&")}</h4>
                  <p>{gallery[index].caption}</p>

                  {
                    tagArray.map((tag, index) => <p key={index} className="gallery-text tag-text">{tag}</p>)
                  }
                </div>
              </div>
            </div>
          )
        }
      }

      galleryArray.push(image);
    }

    let slicedGallery = galleryArray.slice(0, 10);

    return slicedGallery;
  }

  showCaption(e) {
    let captions = document.getElementsByClassName("gallery-text-container-mobile");
    let captionIndex = parseInt(e.currentTarget.dataset.index);
    let button = document.getElementsByClassName("gallery-expand");
    let download = document.getElementsByClassName("download-container-mobile");

    for (let index = 0; index < captions.length; index++) {
      if (captions[index].classList.contains("show-caption") || index !== captionIndex) {
        captions[index].classList.remove("show-caption");

        if (button[index]) {
          button[index].classList.remove("gallery-close");
        }

        if (download[index]) {
          download[index].classList.remove("gallery-close");
        }
      } else {
        captions[index].classList.add("show-caption");

        if (button[index]) {
          button[index].classList.add("gallery-close");
        }

        if (download[index]) {
          download[index].classList.add("gallery-close");
        }
      }
    }
  }

  toggleModal(index) {
    if (typeof window !== undefined) {
      if (window.innerWidth < 912) {
        return;
      }
    }

    let modalIndex = index || index === 0 ? index : this.state.modalIndex;

    this.setState({ modalOpen: !this.state.modalOpen, modalIndex: modalIndex, modalGridView: false }, () => {
      if (this.state.modalOpen) {
        if (typeof window !== 'undefined') {
          window.document.body.classList.add("hidden-scroll");
        }
      } else {
        if (typeof window !== 'undefined') {
          window.document.body.classList.remove("hidden-scroll");
        }
      }
    })
  }

  modalNavigation(e, arrow) {
    let element = e ? e.currentTarget : null;
    let index = this.state.modalIndex;
    let panelInfo = this.state.specificGalleryInfo[0];

    if (element) {
      if (element && element.classList[0].includes("blurred-out")) {
        return;
      } else if (element && element.classList[0].includes("prev") && index !== 0) {
        this.setState({ modalIndex: index - 1 });
      } else if (element && element.classList[0].includes("next") && index !== panelInfo.gallery_data.gallery.length - 1) {
        this.setState({ modalIndex: index + 1 });
      } else if (element && element.classList[0].includes("modal-grid-switch")) {
        this.setState({ modalGridView: !this.state.modalGridView });
      }
    } else if (arrow) {
      if (arrow === "left" && index !== 0) {
        this.setState({ modalIndex: index - 1 });
      } else if (arrow === "right" && index !== panelInfo.gallery_data.gallery.length - 1) {
        this.setState({ modalIndex: index + 1 });
      }
    }
  }

  modalGridLayout(gallery) {
    let gridArray = [];

    for (let index = 0; index < gallery.length; index++) {
      let tallImage = gallery[index].height > gallery[index].width ? true : false;

      let image = (
        <div key={index} className="grid-view__image" onClick={() => this.gridState(index)}>
          <div className="gallery-image-container">
            <img src={gallery[index].src} className={`gallery-cover-image ${tallImage ? 'tall-image' : ''}`} />
            {
              gallery[index].isVideo &&
              <svg className="icon icon--play"><use xlinkHref="#icon-play" /></svg>
            }
          </div>
        </div>
      )

      gridArray.push(image);
    }

    return gridArray;
  }

  gridState(index) {
    if (index || index === 0) {
      this.setState({ modalGridView: false, modalIndex: index });
    }
  }

  handleKeyDown(e) {
    if (this.state.modalOpen) {
      if (e.keyCode === 27) {
        this.toggleModal();
      } else if (e.keyCode === 37) {
        this.modalNavigation(undefined, "left");
      } else if (e.keyCode === 39) {
        this.modalNavigation(undefined, "right");
      }
    }
  }

  monitorModal() {
    if (window.innerWidth < 768 && this.state.modalOpen) {
      if (typeof window !== 'undefined') {
        window.document.body.classList.remove("hidden-scroll");
      }

      this.setState({ modalOpen: false, modalIndex: 0, modalGridView: false });
    }
  }

  render() {
    const props = this.props;
    const panelInfo = this.state.specificGalleryInfo[0];
    const galleryInfo = panelInfo ? panelInfo.gallery_data.gallery : [];

    let isSafari = false;

    if (typeof window !== "undefined") {
      isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    return (
      <React.Fragment>
        {
          this.state.specificGalleryInfo.length > 0 ?
            <React.Fragment>
              <div id="single-gallery-background" className="single-gallery-background subpage-content">
                <div className="single-gallery content">
                  <div className="single-gallery__gallery-container">
                    {
                      galleryInfo.length > 0 ?
                        this.createSpecificGallery(galleryInfo)
                        :
                        <h4 className="single-gallery__no-results">No Results</h4>
                    }
                  </div>
                </div>
              </div>

              {
                this.state.modalOpen ?
                  <div className="gallery-modal-wrapper" aria-modal="true">
                    <div id="gallery-modal" className="gallery-modal content">
                      <button type="button" onClick={this.toggleModal} className="gallery-modal__exit"><svg className="icon--close--search"><use xlinkHref="#icon-close" /></svg></button>
                      <div className={`gallery-modal__image ${this.state.modalGridView ? "grid-view" : ""}`}>
                        {
                          this.state.modalGridView ?
                            <div className="grid-view">
                              {
                                galleryInfo.length > 0 ?
                                  this.modalGridLayout(galleryInfo)
                                  :
                                  <h4 className="single-gallery__no-results">No Results</h4>
                              }
                            </div>
                            :
                            <React.Fragment>
                              {
                                galleryInfo[this.state.modalIndex].isVideo ?
                                  <div className="video-wrapper">
                                    <iframe src={galleryInfo[this.state.modalIndex].link} allowFullScreen loading="lazy"></iframe>
                                  </div>
                                  :
                                  <img src={galleryInfo[this.state.modalIndex].src} />
                              }
                            </React.Fragment>
                        }
                      </div>

                      <div className="gallery-modal__info">
                        {
                          this.state.modalGridView ?
                            <React.Fragment>
                              <h4 className="modal-header">{panelInfo.title.rendered.replace(/&#8211;/g, "-").replace(/&#038;/g, "&").replace(/&#8216;|&#8217;/g, "'").replace(/&amp;/g, "&")}</h4>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <h4 className="modal-header">{galleryInfo[this.state.modalIndex].title.replace(/&#8211;/g, "-").replace(/&#038;/g, "&").replace(/&#8216;|&#8217;/g, "'").replace(/&amp;/g, "&")}</h4>
                              <p className="modal-text">{galleryInfo[this.state.modalIndex].alt.replace(/&#8211;/g, "-").replace(/&#038;/g, "&").replace(/&#8216;|&#8217;/g, "'").replace(/&amp;/g, "&")}</p>
                            </React.Fragment>
                        }

                        {
                          galleryInfo[this.state.modalIndex].downloadLink !== "" ?
                            <div className="download-container">
                              <a href={galleryInfo[this.state.modalIndex].downloadLink} className="image-download">
                                <img src={`${process.env.GATSBY_API_URL}/wp-content/plugins/wp-file-download/app/site/assets/images/theme/download.png`} alt="Download" className={isSafari ? 'safari-only' : ''} />
                              </a>
                            </div>
                            :
                            null
                        }

                        <div className="modal-grid">
                          <button type="button" onClick={this.modalNavigation} className="modal-grid-switch"><img src={btnToggleGrid} className={isSafari ? 'safari-only' : ''} /></button>
                        </div>
                      </div>

                      {
                        !this.state.modalGridView &&
                        <div className="gallery-modal__controls">
                          <button type="button" onClick={this.modalNavigation} className={`prev ${this.state.modalIndex === 0 ? "blurred-out" : ""}`}><svg className="icon icon--arrow-lt"><use xlinkHref="#icon-arrow-lt" /></svg></button>
                          <p className="controls-text">{`${this.state.modalIndex + 1} of ${galleryInfo.length}`}</p>
                          <button type="button" onClick={this.modalNavigation} className={`next ${this.state.modalIndex === galleryInfo.length - 1 ? "blurred-out" : ""}`}><svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg></button>
                        </div>
                      }
                    </div>
                  </div>
                  :
                  null
              }
            </React.Fragment>
            :
            null
        }
      </React.Fragment>
    );
  }
}

export default Gallery;


