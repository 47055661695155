import React from "react";
import Helmet from "react-helmet";


class SocialMediaPanel extends React.Component {
  constructor(props) {
    super(props);
    this.copyUrlToClipboard = this.copyUrlToClipboard.bind(this);
  }

  state = {
    currentPage: ""
  }

  componentDidMount() {
    let url = "";
    if (typeof window !== undefined) {
      url = window.location.href;
    }

    this.setState({ currentPage: url });
  }

  copyUrlToClipboard() {
    const body = document.getElementsByTagName('body')[0];
    const tempInput = document.createElement("INPUT");
    body.appendChild(tempInput);
    tempInput.setAttribute("value", this.state.currentPage);
    tempInput.select();
    document.execCommand("copy");
    body.removeChild(tempInput);
  }


  render() {
    const props = this.props;
    // const testUrl = "https://polb-stage.stellaragency.com/business/permits%23filming-special-events-permit/location-scouting-info";
    const hashedUrl = this.state.currentPage.replace("#", "%23");
    const urlLink = `https://polb.com/port-info/news-and-press${props.data.link.split(".com")[1]}`;
    // console.log(urlLink);
    // console.log(props.data);

    return (
      <React.Fragment>
        <div className="social__media__panel">
          <a href={`https://facebook.com/sharer.php?u=${hashedUrl}`} rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Facebook page"><svg className="icon icon--footer"><use xlinkHref="#icon-facebook" /></svg></a>
          <a href={`https://twitter.com/intent/tweet?url=${hashedUrl}`} rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach Twitter page"><svg className="icon icon--footer"><use xlinkHref="#icon-twitter" /></svg></a>
          <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${hashedUrl}`} rel="noopener noreferrer" target="_blank" aria-label="Port of Long Beach LinkedIn page"><svg className="icon icon--footer"><use xlinkHref="#icon-linkedin" /></svg></a>
          <button type="button" onClick={this.copyUrlToClipboard}><svg className="icon icon--footer"><use xlinkHref="#icon-link" /></svg></button>
        </div>
      </React.Fragment>
    )
  }
}


export default SocialMediaPanel;

