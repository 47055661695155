import React from "react";


class SingleFileDownload extends React.Component {
  render() {
    const data = this.props.data;
    let fileSize = data.children[3].children[3].children[1].children[0].data.substring(11).split(" ");
    let formattedFileSize = `${Math.round(parseFloat(fileSize[0]))}${fileSize[1]}`;
    let fileTypeArray = data.children[5].children[1].attribs.href.split(".");
    let fileType = fileTypeArray[3].substring(0,3);

    return (
      <table className="files">
        <caption className="visuallyhidden">Downloadable file</caption>
        <tbody>
          <tr key={this.props.index} className="single-file-download">
            <td>{data.children[3].children[1].children[0].children[0].data.trim()}</td>
            <td>{formattedFileSize}</td>
            <td><a href={`${data.children[5].children[1].attribs.href}`} rel="noopener noreferrer" target="_blank">{fileType}</a></td>
          </tr>
        </tbody>
      </table>
    )
  }
}


export default SingleFileDownload;

// Math.round(data.children[1].children[3].children[5].children[2].data.trim())




// import React from "react";


// class SingleFileDownload extends React.Component {
//   render() {
//     const data = this.props.data;

//     return (
//       <table className="files">
//         <caption className="visuallyhidden">Downloadable file</caption>
//         <tbody>
//           <tr key={this.props.index} className="single-file-download">
//             <td>{data.children[1].children[3].children[1].children[0].data.trim()}</td>
//             <td>{data.children[1].children[3].children[5].children[2].data.trim()}</td>
//             <td><a href={`${data.children[1].children[1].attribs.href}`} rel="noopener noreferrer" target="_blank">{data.children[1].children[3].children[5].children[4].data}</a></td>
//           </tr>
//         </tbody>
//       </table>
//     )
//   }
// }


// export default SingleFileDownload;

// // Math.round(data.children[1].children[3].children[5].children[2].data.trim())